



























































































































































































































































import Vue from 'vue';
import { ApolloError, ApolloQueryResult } from 'apollo-client';
import { Table, TableColumn } from 'element-ui';
import { GET_POINTS_QUERY } from '../queries/query';
import {
  PointEdge,
  PointAPIResponse,
  PointConnection,
  PointKind,
  PointAccountingType,
  PointFilter,
  PointsRequestParam,
  SavedLink,
  UsedHistory,
  PointRefType,
} from '../model/point';
import Instant from '@/components/Labels/Instant.vue';
import PointListFilter from '../components/PointListFilter.vue';

enum PointModalType {
  USED_HISTORY,
  SAVED_LINK,
}

export default Vue.extend({
  name: 'PointList',
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    Instant,
    PointListFilter,
  },
  data() {
    return {
      point: { points: { edges: [] as PointEdge[] } },
      title: '에너지 관리',
      page: this.$route.query.page || 1,
      size: this.$route.query.pageSize || 10,
      totalRows: 0,
      disabled: false,
      pointTitle: '',
      issuableTerm: {
        startedAt: '',
        endedAt: '',
      },
      PointModalType,
      PointAccountingType,
      showUsedHistoriesModal: false,
      showSavedLinksModal: false,
      usedHistories: [] as UsedHistory[],
      savedLinks: [] as SavedLink[],
      kind: [
        { value: 'all', text: '전체보기' },
        { value: 'saved', text: '지급 내역만 보기' },
        { value: 'saved-cancelled', text: '지급취소 내역만 보기' },
        { value: 'used', text: '사용 내역만 보기' },
        { value: 'used-cancelled', text: '사용취소 내역만 보기' },
        { value: 'expired', text: '만료 내역만 보기' },
      ],
      accountingType: [
        { value: 'all', text: '전체보기' },
        { value: PointAccountingType.PROMOTION, text: '광고선전비' },
        { value: PointAccountingType.WELFARE, text: '복리후생비' },
        { value: PointAccountingType.BUSINESS, text: '그 외' },
        { value: PointAccountingType.REWARD, text: '구매 리워드' },
        { value: PointAccountingType.NONE, text: '해당없음' },
      ],
      kindValue: 'all',
      accountingTypeValue: 'all',
      searchFilter: {} as PointFilter,
      filter: {} as PointFilter,
    };
  },
  computed: {
    points(): PointAPIResponse[] {
      if (this.point.points.edges) {
        return this.point.points.edges.map(edge => edge.node);
      } else {
        return [];
      }
    },
  },
  activated: async function (): Promise<void> {
    this.page = this.$route.query.page || 1;
    this.size = this.$route.query.pageSize || 10;
    this.searchFilter = {
      refType: (this.$route.query?.refType as PointRefType) || null,
      refId: (this.$route.query?.refId as string) || null,
      userId:
        this.$route.query?.userId !== null &&
        this.$route.query?.userId !== undefined
          ? Number(this.$route.query?.userId)
          : null,
      typeId:
        this.$route.query?.typeId !== null &&
        this.$route.query?.typeId !== undefined
          ? Number(this.$route.query?.typeId)
          : null,
      accountingType:
        (this.$route.query?.accountingType as PointAccountingType) || null,
    };
    await this.getList();
  },
  methods: {
    async resetSearchFilter(): Promise<void> {
      this.page = 1;
      this.size = 10;
      this.searchFilter = {};
      await this.getList();
    },
    async getList(): Promise<void> {
      try {
        this.disabled = true;
        this.filter = {
          userId:
            this.searchFilter.userId !== null
              ? Number(this.searchFilter.userId)
              : null,
          typeId:
            this.searchFilter.typeId !== null
              ? Number(this.searchFilter.typeId)
              : null,
          kind: this.searchFilter.kind,
          cancelled: this.searchFilter.cancelled,
          accountingType: this.searchFilter.accountingType,
          refType: this.searchFilter.refType,
          refId: this.searchFilter.refId,
        };
        await this.$apollo.queries.point.refetch();
      } catch (error) {
        let errorMessage = `알수없는 오류`;
        if (error instanceof Error) {
          errorMessage = error.message;
        }
        this.$notify({
          title: '요청 실패',
          type: 'warning',
          message: `에너지 내역을 불러오는데 실패하였습니다. (${errorMessage})`,
          position: 'bottom-right',
        });
      } finally {
        this.disabled = false;
      }
    },
    convertKindToString(kind: PointKind, cancelled: boolean): string {
      switch (kind) {
        case PointKind.SAVED:
          return cancelled ? '지급 취소로 인한 회수 (-)' : '지급됨 (+)';
        case PointKind.USED:
          return cancelled ? '사용 취소로 인한 재지급 (+)' : '사용됨 (-)';
        case PointKind.EXPIRED:
          return '유효기간 만료됨 (-)';
        default:
          return '알수없음';
      }
    },
    convertAmountToString(amount: number, usedAmount: number): string {
      if (amount < 0) {
        return `${Math.abs(amount).toLocaleString()} 에너지 사용/만료됨`;
      } else {
        return `${Math.abs(
          amount
        ).toLocaleString()} 에너지 지급됨<br />${Math.abs(
          usedAmount
        ).toLocaleString()} 에너지 사용/만료됨<br />${Math.abs(
          amount - usedAmount
        ).toLocaleString()} 에너지 사용가능`;
      }
    },
    convertAccountingType(accountingType: PointAccountingType): string {
      switch (accountingType) {
        case PointAccountingType.PROMOTION:
          return '광고선전비';
        case PointAccountingType.WELFARE:
          return '복리후생비';
        case PointAccountingType.BUSINESS:
          return '그 외';
        case PointAccountingType.NONE:
          return '해당없음';
        case PointAccountingType.REWARD:
          return '구매 리워드';
        default:
          return '';
      }
    },
    convertPeriod(appliedAt: Date, expiredAt: Date | null): string {
      if (!expiredAt) {
        return '-';
      } else {
        return `${this.$moment(appliedAt).format(
          'YYYY-MM-DD 00:00'
        )}<br />~<br />${this.$moment(expiredAt).format('YYYY-MM-DD 23:59')}`;
      }
    },
    async pageClick(bvEvent: never, page: number): Promise<void> {
      if (
        this.$route.path !== `/points/list?page=${page}&pageSize=${this.size}`
      ) {
        this.$router.push(`/points/list?page=${page}&pageSize=${this.size}`);
      }
    },
    showModalClick(
      modalType: PointModalType,
      array: (UsedHistory | SavedLink)[]
    ): void {
      if (modalType === PointModalType.USED_HISTORY) {
        this.savedLinks = [];
        this.usedHistories = array as UsedHistory[];
        this.showUsedHistoriesModal = true;
      } else if (modalType === PointModalType.SAVED_LINK) {
        this.savedLinks = array as SavedLink[];
        this.usedHistories = [];
        this.showSavedLinksModal = true;
      }
    },
  },
  apollo: {
    point: {
      query: GET_POINTS_QUERY,
      variables(): PointsRequestParam {
        this.disabled = true;
        return {
          page: Number(this.page),
          size: Number(this.size),
          filter: this.filter,
        };
      },
      error(e: ApolloError): void {
        this.disabled = false;
        console.error(e);
      },
      result(
        result: ApolloQueryResult<{ point: { points: PointConnection } }>
      ): void {
        this.disabled = false;
        this.totalRows = result.data.point.points.totalCount;
      },
      update: data => data.point,
    },
  },
});
