




















































import { CommonState } from '@frientrip/domain';
import { Select, Option } from 'element-ui';
import { ApolloError, ApolloQueryResult } from 'apollo-client';
import Vue from 'vue';
import { PointAccountingType, PointKind } from '../model/point';
import {
  PointTypeConnection,
  PointTypeEdge,
  PointTypeFilter,
} from '../model/pointType';
import { POINT_TYPES_QUERY } from '../queries/query';

interface PointTypesRequestParam {
  page?: number;
  size?: number;
  filter?: PointTypeFilter;
}

interface TypeOption {
  value: string | null;
  text: string;
}

export default Vue.extend({
  name: 'PointListFilter',
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },
  props: ['disabled', 'searchFilter'],
  data() {
    return {
      point: { types: { edges: [] as PointTypeEdge[] } },
      kindOptions: [
        { value: 'all', text: '전체보기' },
        { value: 'saved', text: '지급 내역만 보기' },
        { value: 'saved-cancelled', text: '지급취소 내역만 보기' },
        { value: 'used', text: '사용 내역만 보기' },
        { value: 'used-cancelled', text: '사용취소 내역만 보기' },
        { value: 'expired', text: '만료 내역만 보기' },
      ],
      accountingTypeOptions: [
        { value: null, text: '전체보기' },
        { value: PointAccountingType.PROMOTION, text: '광고선전비' },
        { value: PointAccountingType.WELFARE, text: '복리후생비' },
        { value: PointAccountingType.REWARD, text: '구매 리워드' },
        { value: PointAccountingType.BUSINESS, text: '그 외' },
        { value: PointAccountingType.NONE, text: '해당없음' },
      ],
      typeOptions: [{ value: null, text: '전체보기' }] as TypeOption[],
      kindValue: 'all',
    };
  },
  watch: {
    kindValue: function (newVal, oldVal) {
      if (newVal === oldVal) {
        return;
      }

      if (newVal === 'all') {
        this.$props.searchFilter.kind = null;
        this.$props.searchFilter.cancelled = null;
      } else if (newVal === 'saved') {
        this.$props.searchFilter.kind = PointKind.SAVED;
        this.$props.searchFilter.cancelled = false;
      } else if (newVal === 'saved-cancelled') {
        this.$props.searchFilter.kind = PointKind.SAVED;
        this.$props.searchFilter.cancelled = true;
      } else if (newVal === 'used') {
        this.$props.searchFilter.kind = PointKind.USED;
        this.$props.searchFilter.cancelled = false;
      } else if (newVal === 'used-cancelled') {
        this.$props.searchFilter.kind = PointKind.USED;
        this.$props.searchFilter.cancelled = true;
      } else if (newVal === 'expired') {
        this.$props.searchFilter.kind = PointKind.EXPIRED;
        this.$props.searchFilter.cancelled = false;
      }
    },
  },
  methods: {
    search(): void {
      this.$emit('getList');
    },
  },
  apollo: {
    point: {
      query: POINT_TYPES_QUERY,
      variables(): PointTypesRequestParam {
        return {
          page: 1,
          size: 100,
          filter: {
            statusIn: [
              CommonState.ACTIVE,
              CommonState.INACTIVE,
              CommonState.DELETED,
            ],
          } as PointTypeFilter,
        };
      },
      error(e: ApolloError): void {
        console.error(e);
      },
      result(
        result: ApolloQueryResult<{
          point: { types: PointTypeConnection };
        }>
      ): void {
        this.typeOptions = this.typeOptions.concat(
          result.data.point.types.edges.map(edge => {
            return { value: edge.node.id, text: edge.node.name };
          })
        );
      },
    },
  },
});
